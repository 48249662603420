import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './Components/Sass/main.scss'
import Home from './Components/Pages/Home';
import PrivacyPolicy from './Components/Pages/PrivacyPolicy'
import CallaxoBD from './Components/Pages/CallaxoBD';
import CallaxoMax from './Components/Pages/CallaxoMax';
import CallaxoK2 from './Components/Pages/CallaxoK2';
import CallaxoPlus from './Components/Pages/CallaxoPlus';


function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products/callaxo-max" element={<CallaxoMax />} />
          <Route path="/products/callaxo-bd" element={<CallaxoBD />} />
          <Route path="/products/callaxo-k2" element={<CallaxoK2 />} />
          <Route path="/products/callaxo-plus" element={<CallaxoPlus />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
