import React from 'react'

const banner = () => {
    return (
        <div className='banner'>
            <div className="banner-shape"></div>
            <div className="banner-shape2"></div>
            <div className="banner-text">
                <span>Welcome To</span>
                <h1>Callaxo</h1>
                <p>Experience the comprehensive wellness benefits of Callaxo, featuring a range of premium products designed to optimize your health. Callaxo-Max enhances bone health, treats anemia, and supports overall well-being, while Callaxo-BD boosts immunity and promotes proper growth. Stay tuned for more information on Callaxo-K2 and Callaxo Plus, your trusted partners in holistic wellness.</p>
                <a href="#our-products">Our Products</a>
            </div>
            <div className="banner-img">
                <div className="banner-img-content img1" >
                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1712750092/Callaxo/Banner/4_l8g8jy.png" alt="product1" />
                </div>
                <div className="banner-img-content img2">
                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1712750092/Callaxo/Banner/3_l1yppf.png" alt="product2" />
                </div>
                <div className="banner-img-content img3">
                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1712750090/Callaxo/Banner/1_tdtylw.png" alt="product3" />
                </div>
                <div className="banner-img-content img4">
                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1712750090/Callaxo/Banner/2_lo5d49.png" alt="product3" />
                </div>
            </div>
        </div>
    )
}

export default banner
