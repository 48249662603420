import React from 'react'

const InsideHeading = (props) => {
  return (
    <div className='inside-heading' id={props.id}>
      <h3>{props.h3}</h3>
      <p>{props.p}</p>
      <p>{props.p2}</p>

      <div className="key-content" style={{ display: props.keyDisplay }}>
        <p>
          <svg id="Capa_1" enable-background="new 0 0 512.003 512.003" height="512" viewBox="0 0 512.003 512.003" width="512" xmlns="http://www.w3.org/2000/svg"><g><g><path d="m256.001 512.003-255.997-256.002 255.997-256.001 255.998 256.001zm-213.572-256.002 213.572 213.575 213.572-213.575-213.572-213.574z" /></g><g><path d="m256.001 427.149-171.145-171.148 171.145-171.147 171.146 171.148zm-128.72-171.148 128.72 128.721 128.72-128.721-128.72-128.721z" /></g></g></svg> &emsp;
          {props.key1}
        </p>
        <p style={{ display: props.k2 }}>
          <svg id="Capa_1" enable-background="new 0 0 512.003 512.003" height="512" viewBox="0 0 512.003 512.003" width="512" xmlns="http://www.w3.org/2000/svg"><g><g><path d="m256.001 512.003-255.997-256.002 255.997-256.001 255.998 256.001zm-213.572-256.002 213.572 213.575 213.572-213.575-213.572-213.574z" /></g><g><path d="m256.001 427.149-171.145-171.148 171.145-171.147 171.146 171.148zm-128.72-171.148 128.72 128.721 128.72-128.721-128.72-128.721z" /></g></g></svg> &emsp;
          {props.key2}
        </p>
        <p style={{ display: props.k3 }}>
          <svg id="Capa_1" enable-background="new 0 0 512.003 512.003" height="512" viewBox="0 0 512.003 512.003" width="512" xmlns="http://www.w3.org/2000/svg"><g><g><path d="m256.001 512.003-255.997-256.002 255.997-256.001 255.998 256.001zm-213.572-256.002 213.572 213.575 213.572-213.575-213.572-213.574z" /></g><g><path d="m256.001 427.149-171.145-171.148 171.145-171.147 171.146 171.148zm-128.72-171.148 128.72 128.721 128.72-128.721-128.72-128.721z" /></g></g></svg> &emsp;
          {props.key3}
        </p>
        <p style={{ display: props.k4 }}>
          <svg id="Capa_1" enable-background="new 0 0 512.003 512.003" height="512" viewBox="0 0 512.003 512.003" width="512" xmlns="http://www.w3.org/2000/svg"><g><g><path d="m256.001 512.003-255.997-256.002 255.997-256.001 255.998 256.001zm-213.572-256.002 213.572 213.575 213.572-213.575-213.572-213.574z" /></g><g><path d="m256.001 427.149-171.145-171.148 171.145-171.147 171.146 171.148zm-128.72-171.148 128.72 128.721 128.72-128.721-128.72-128.721z" /></g></g></svg> &emsp;
          {props.key4}
        </p>
        <p style={{ display: props.k5 }}>
          <svg id="Capa_1" enable-background="new 0 0 512.003 512.003" height="512" viewBox="0 0 512.003 512.003" width="512" xmlns="http://www.w3.org/2000/svg"><g><g><path d="m256.001 512.003-255.997-256.002 255.997-256.001 255.998 256.001zm-213.572-256.002 213.572 213.575 213.572-213.575-213.572-213.574z" /></g><g><path d="m256.001 427.149-171.145-171.148 171.145-171.147 171.146 171.148zm-128.72-171.148 128.72 128.721 128.72-128.721-128.72-128.721z" /></g></g></svg> &emsp;
          {props.key5}
        </p>
        <p style={{ display: props.k6 }}>
          <svg id="Capa_1" enable-background="new 0 0 512.003 512.003" height="512" viewBox="0 0 512.003 512.003" width="512" xmlns="http://www.w3.org/2000/svg"><g><g><path d="m256.001 512.003-255.997-256.002 255.997-256.001 255.998 256.001zm-213.572-256.002 213.572 213.575 213.572-213.575-213.572-213.574z" /></g><g><path d="m256.001 427.149-171.145-171.148 171.145-171.147 171.146 171.148zm-128.72-171.148 128.72 128.721 128.72-128.721-128.72-128.721z" /></g></g></svg> &emsp;
          {props.key6}
        </p>
        <p style={{ display: props.k7 }}>
          <svg id="Capa_1" enable-background="new 0 0 512.003 512.003" height="512" viewBox="0 0 512.003 512.003" width="512" xmlns="http://www.w3.org/2000/svg"><g><g><path d="m256.001 512.003-255.997-256.002 255.997-256.001 255.998 256.001zm-213.572-256.002 213.572 213.575 213.572-213.575-213.572-213.574z" /></g><g><path d="m256.001 427.149-171.145-171.148 171.145-171.147 171.146 171.148zm-128.72-171.148 128.72 128.721 128.72-128.721-128.72-128.721z" /></g></g></svg> &emsp;
          {props.key7}
        </p>
        <p style={{ display: props.k8 }}>
          <svg id="Capa_1" enable-background="new 0 0 512.003 512.003" height="512" viewBox="0 0 512.003 512.003" width="512" xmlns="http://www.w3.org/2000/svg"><g><g><path d="m256.001 512.003-255.997-256.002 255.997-256.001 255.998 256.001zm-213.572-256.002 213.572 213.575 213.572-213.575-213.572-213.574z" /></g><g><path d="m256.001 427.149-171.145-171.148 171.145-171.147 171.146 171.148zm-128.72-171.148 128.72 128.721 128.72-128.721-128.72-128.721z" /></g></g></svg> &emsp;
          {props.key8}
        </p>
        <p style={{ display: props.k9 }}>
          <svg id="Capa_1" enable-background="new 0 0 512.003 512.003" height="512" viewBox="0 0 512.003 512.003" width="512" xmlns="http://www.w3.org/2000/svg"><g><g><path d="m256.001 512.003-255.997-256.002 255.997-256.001 255.998 256.001zm-213.572-256.002 213.572 213.575 213.572-213.575-213.572-213.574z" /></g><g><path d="m256.001 427.149-171.145-171.148 171.145-171.147 171.146 171.148zm-128.72-171.148 128.72 128.721 128.72-128.721-128.72-128.721z" /></g></g></svg> &emsp;
          {props.key9}
        </p>
        <p style={{ display: props.k10 }}>
          <svg id="Capa_1" enable-background="new 0 0 512.003 512.003" height="512" viewBox="0 0 512.003 512.003" width="512" xmlns="http://www.w3.org/2000/svg"><g><g><path d="m256.001 512.003-255.997-256.002 255.997-256.001 255.998 256.001zm-213.572-256.002 213.572 213.575 213.572-213.575-213.572-213.574z" /></g><g><path d="m256.001 427.149-171.145-171.148 171.145-171.147 171.146 171.148zm-128.72-171.148 128.72 128.721 128.72-128.721-128.72-128.721z" /></g></g></svg> &emsp;
          {props.key10}
        </p>
        <p style={{ display: props.k11 }}>
          <svg id="Capa_1" enable-background="new 0 0 512.003 512.003" height="512" viewBox="0 0 512.003 512.003" width="512" xmlns="http://www.w3.org/2000/svg"><g><g><path d="m256.001 512.003-255.997-256.002 255.997-256.001 255.998 256.001zm-213.572-256.002 213.572 213.575 213.572-213.575-213.572-213.574z" /></g><g><path d="m256.001 427.149-171.145-171.148 171.145-171.147 171.146 171.148zm-128.72-171.148 128.72 128.721 128.72-128.721-128.72-128.721z" /></g></g></svg> &emsp;
          {props.key11}
        </p>

      </div>

      <div className="benefits-content" style={{ display: props.benefitDisplay }}>
        <ul>
          <li>{props.benefit1}</li>
          <li style={{ display: props.b2 }}>{props.benefit2}</li>
          <li style={{ display: props.b3 }}>{props.benefit3}</li>
          <li style={{ display: props.b4 }}>{props.benefit4}</li>
          <li style={{ display: props.b5 }}>{props.benefit5}</li>
          <li style={{ display: props.b6 }}>{props.benefit6}</li>
          <li style={{ display: props.b7 }}>{props.benefit7}</li>
          <li style={{ display: props.b8 }}>{props.benefit8}</li>
          <li style={{ display: props.b9 }}>{props.benefit9}</li>
          <li style={{ display: props.b10 }}>{props.benefit10}</li>
        </ul>
      </div>

    </div>
  )
}

export default InsideHeading
